.navbar_pc {
    width: 100%;
    background-color: rgba(0, 80, 150, 1) !important;
    div {
        width: 1400px;
        margin:0 auto;
        display: flex;
        float: none;
        a {
            flex: 1;
            display: block;
            text-align: center;
            font-weight: 700;
            font-size: 16px;
        }
    }

}

.content_pc {
    width:1200px;
    margin: 0 auto;
    min-height: 500px;
    overflow: hidden;
    .children_pc {
        width: 800px;
        float: left;
    }
    .rightBar_pc {
        width: 400px;
        float: left;
    }
}

.layout {
    background-color: #fff;
}

.text_rightbar_pc {
    font-size: 22px;
    text-align: center;
    padding: 20px 0px 10px; 
}

@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;